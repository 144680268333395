import {React, useState, useEffect} from 'react'

import ProductRender from '../ProductRender'
import FilterRender from '../FilterRender'
import PromoRender from '../PromoRender'
import axios from 'axios'

import {FiChevronDown} from 'react-icons/fi'

export default function Conveniencia(props) {
    const[filtered, setFiltered] = useState([])
    const[search, setSearch] = useState('')
    const[showFilter, setShowFilter] = useState(false)
    const[productsDb, setProductsDb] = useState([])

    useEffect(() => {
        axios.get(props.apiUrl + 'products')
        .then(res=> setProductsDb(res.data))
        .catch(err=> console.log(err))

        filterBySub("")

    }, [])



    const barbecue = productsDb.filter(prod=> {
        
        if(prod.group === props.convProps){
            return prod;
        }
      
    })

    

    const sort = require('../../utils/sort').sortSub;

    const subcategories = sort(barbecue);
    console.log(barbecue)

    const searchFilterFunction  = (text) =>{
        if(text){
            var newData = barbecue.filter(
                item =>{
                    var itemData = item.name.toUpperCase()
                    var textData = text.toUpperCase()

                    return itemData.indexOf(textData) > -1;
                });

                setFiltered(newData)
                setSearch(text)
        }
        else{
            setFiltered(barbecue.products)
            setSearch(text)
        }
    }

    const checkAvailability = require('../../utils/utils').checkAvailability
    
    const availableFiltered = filtered ? filtered.filter(prod => checkAvailability(prod)) : ""

  
    const filterBySubName = (text) =>{
        showFilters()
        filterBySub(text)

    }

    const filterBySub =(text)=>{
        
        if(text){
            var newData = barbecue.filter(
                item =>{
                    var itemData = item.subGroup.toUpperCase()
                    var textData = text.toUpperCase()

                    return itemData.indexOf(textData) > -1;
                });

                setFiltered(newData)
                setSearch(text)
        }
        else{
            setFiltered(barbecue.products)
            setSearch(text)
        }
    }

    const showFilters = () =>{
        showFilter ? setShowFilter(false) : setShowFilter(true)
    }

    
    const DataRender = ()=>{
        if(filtered && filtered.length > 0){
           return <FilterRender
            foo={props.foo}
            menu={availableFiltered}
            />
    
        }
        else{

            return <div>                
                        <div>
                            
                            <PromoRender
                                category={barbecue}
                                foo={props.foo}
                            />
                        </div>
                        {subcategories.map(sub=>(
                            <div>
                                <h2 className="sub-group">{sub}</h2>
                                <ProductRender
                                    foo={props.foo}
                                    sub={sub}
                                    category={barbecue}
            
                                    />
                            </div>
                        ))}

   
            </div>
    
        }}


    return (
        <div>
            <div className="filter-by-sub">
                <p style={{lineHeight: '0.8em', marginLeft: '10px'}}>Filtrar por: </p>
                
                <p onClick={showFilters} className="filter-sub">Mostrar filtros <FiChevronDown /></p>
                <p onClick={()=>filterBySub("")} className="filter-sub">Remover filtro x</p>

                <div className="filter-by-sub" style={{display: showFilter ? 'flex' : 'none'}}>
                    {subcategories.map(sub=>(
                        <p onClick={()=>filterBySubName(sub)} className="filter-sub" key={sub}>{sub}</p>
                    ))}
                </div>

            </div>
            <form className="search-box">
                <input placeholder="Pesquise por nome" onChange={(e)=>searchFilterFunction(e.target.value)}></input>
            </form>

            <DataRender />
            
        </div>
    )
}

