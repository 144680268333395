import React from 'react'

export default function ProductRender(props) {

    const menu = props.category.filter(prod=>{
        if(prod.subGroup === props.sub){
            return prod;
        }
    })

    const checkAvailability = require('../utils/utils').checkAvailability



    const available = menu.filter(prod => checkAvailability(prod))

    const RenderGarcom = () =>{
        
    }


    return (
        <div >           
            {available.map(item =>(
                            <div className="product-box" onClick={()=>{props.foo(item)}} >
                            <div className="img-box">
                                <img src={item.photo} alt=""></img>
                            </div>
                            <div>
                                <h4>{item.name} </h4>
                                <p>{item.short}</p>
                                <p ><b>R$ {item.price.toFixed(2)}</b></p>
            
                            </div>                            
                        </div>
                       
            ))}
        </div>
    )
}
