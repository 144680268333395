import React from 'react'

export default function PromoRender(props) {
    const menu = props.category.filter(prod=>{
        if(prod.promo){
            return prod;
        }
    })

    const checkAvailability = require('../utils/utils').checkAvailability


    const available = menu.filter(prod => checkAvailability(prod))

    return (
        <div>
            <h2 className="sub-group">{menu.length > 0 ? 'PROMOÇÕES' : ''}</h2>
            <br></br>
                {available.map(item =>(
                            <div className="product-box" onClick={()=>{props.foo(item)}} >
                            <div className="img-box">
                                <img src={item.photo ? item.photo : ''} alt=""></img>
                            </div>
                            <div>
                                <h4>{item.name} </h4>
                                <p>{item.short}</p>
                                <p style={{WebkitTextDecorationLine: 'line-through', textDecoration:  'line-through #6b6e7e', fontSize: '0.8em'}}>{item.price}</p>
                                <p ><b>R$ {item.promoPrice.toFixed(2)}</b></p>
            
                            </div>                            
                        </div>
                       
            ))}
            
        </div>
    )
}
