import logo from './logo.svg';
import './App.css';
import {React, useState, useEffect} from 'react'

import Menu from './components/Menu'
import axios from 'axios';
import Site from './components/pages/Site';



function App() {
  
  const apiUrl = 'https://backend.rapidaofraiburgo.com.br/delivery/';
  const [shop, setShop] = useState([])

  useEffect(() => {
    axios.get(apiUrl + 'shop')
    .then(res=>setShop(res.data))
    .catch(err=>console.log(err))

  }, [])

  const WideOrMobile =()=>{
    return window.innerWidth < 600 ? <Menu shop={shop} apiUrl={apiUrl}/> : <Site />
  }
  return (
    <div className="App">

      <WideOrMobile />
      

      
    </div>

  );
}

export default App;
