import React, {useEffect, useState} from 'react';
import { IoAddCircleSharp, IoBasketOutline} from "react-icons/io5";

import { FiShoppingBag } from "react-icons/fi";
import axios from 'axios';

import {BiChevronLeftCircle} from 'react-icons/bi'
import {HiCheck} from 'react-icons/hi'
import {IoIosRemove, IoIosCloseCircle} from 'react-icons/io'
import {GrFormAdd} from 'react-icons/gr'


import useSound from 'use-sound';

import click from '../assets/sound/487452__ranner__click.wav'
import Bordas from './components.utils/Bordas';
import Sabores from './components.utils/Sabores';
import Acompanhamentos from './components.utils/Acompanhamentos';

export default function Product(props) {



    
    const[observation, setObservation] = useState('')
    const[missingPoint , setMissingPoint] = useState(false)

   
    const[quantity, setQuantity] = useState(1)
    const[burgerExtra, setBurgerExtra] = useState(
        [{"_id":"601dc89114959d19f0dc691d","code":27,"name":"Bacon","short":"","long":"","photo":"","pontuation":null,"price":2,"isAvailable":true,"table":"PADRÃO","mode":"Todos","group":"Adicional Burger","__v":0},
        {"_id":"601dc8ad14959d19f0dc691e","code":28,"name":"Carne","short":"","long":"","photo":"","pontuation":null,"price":8,"isAvailable":true,"table":"PADRÃO","mode":"Todos","group":"Adicional Burger",
        "onCart": false,"__v":0},
        {"_id":"601dc8be14959d19f0dc691f","code":29,"name":"Cheddar","short":"",
        "onCart": false,"long":"","photo":"","pontuation":null,"price":2,"isAvailable":true,"table":"PADRÃO","mode":"Todos","group":"Adicional Burger","__v":0},
        {"_id":"601dc8d014959d19f0dc6920","code":30,"name":"Maionese Caseira","short":"","long":"","photo":"","pontuation":null,"price":1.25,"isAvailable":true,"table":"PADRÃO","mode":"Todos","group":"Adicional Burger","__v":0},
        {"_id":"601dc8d014959d19f0dc6920","code":84,"name":"Ovo","short":"","long":"","photo":"","pontuation":null,"price":2.00,"isAvailable":true,"table":"PADRÃO","mode":"Todos","group":"Adicional Burger","__v":0}]

    )
    const[extras, setExtras] = useState([
    //     {
    //     code: 16,
    //     name: "Maionese - 350gr",
    //     price: 12.00,
    //     group: "Acompanhamentos",
    //     table: "Padrão",
    //     mode: "Todos",
    //     onCart: false
    // },
    {
        code: 17,
        name: "Pão de Alho",
        price: 8.00,
        group: "Acompanhamentos",
        table: "Padrão",
        mode: "Todos",
        onCart: false
    },
    // {
    //     code: 18,
    //     name: "Farofa",
    //     price: 6.00,
    //     group: "Acompanhamentos",
    //     table: "Padrão",
    //     mode: "Todos",
    //     onCart: false
    // }
    // ,
    {
        code: 4,
        name: "Fritas - 200gr",
        price: 9.00,
        group: "Acompanhamentos",
        table: "Padrão",
        mode: "Todos",
        onCart: false
    }
    // ,

    // {
    //     code: 19,
    //     name: "Salada de F. Verdes",
    //     price: 10.00,
    //     group: "Acompanhamentos",
    //     table: "Padrão",
    //     mode: "Todos",
    //     onCart: false
    // },
    // {
    //     code:   76,
    //     name: "Queijo coalho c/ Geléia de Pimenta",
    //     price: 10.00,
    //     group: "Acompanhamentos",
    //     table: "Padrão",
    //     mode: "Todos",
    //     onCart: false
    // }
])




    const handleObs = (value) =>{    
        props.setObservation(value)
    }
    
    
    const[addOns, setAddOns] = useState()
    const[pizzaFlavours, setPizzaFlavours] = useState()
    const[pizzaBorders, setPizzaBorders] = useState()
    const[limit, setLimit] = useState(1)
    const [showAddOns, setShowAddOns] = useState(false)

    useEffect(()=>{
        axios.get(props.apiUrl + 'addOns')
        .then(res=> setAddOns(res.data))

        axios.get(props.apiUrl + 'flavours')
        .then(res=> {setPizzaFlavours(res.data)
            
        })

        axios.get(props.apiUrl + 'bordas')
        .then(res=> setPizzaBorders(res.data))
    },[])

    useEffect(()=>{
        // console.log(props.product.group)
        if(props.product.group === "Rapiburguer" || props.product.group === "Porções  "){
            setShowAddOns(true)
        }
    },[props.product])

    const hereAddOns = addOns?.filter(i=>{
        // console.log(props.product)


            if(props.product.group === 'Bebidas '){
                if(props.product.subGroup === 'Sucos de Polpa' && i.category.indexOf(props.product.group) >= 0 ){
                    return i
                }
            }

            else{
                if(i.category === props.product.group){
                    return i
                }
        
                else if(i.category.indexOf(props.product.group) >= 0){
                    return i
                }
    
            }
        

    })

    const hereFlavours = pizzaFlavours?.filter(i=>{
                // console.log(props.product)
                if(props.product.group === 'RapiEsfihas'){
                    
                    if(i.subGroup === props.product.subGroup){
                        // console.log(i)
                        return i
                    }
                }


                // console.log(i.category.indexOf(props.product.group))
               else if(i.category.indexOf(props.product.group) >= 0 ){ 
                              
                    return i
                }

    
       


        // if(i.category === props.product.group){
        //     return i
        // }
    })


    const hereBorders = pizzaBorders?.filter(i=>{

        if(i.category === props.product.group){
            return i
        }

    })




    const [sushiExtra, setSushiExtra] = useState([
    ])



    const [play] = useSound(click);

    const [extraAdds, setExtraAdds] = useState([
    ])




    const addOne = ()=>{
        setQuantity(current=> current + 1)
    }

    const decreaseOne = ()=>{
        if(quantity > 1){
        setQuantity(current=> current - 1)
        }
        else setQuantity(1)
    }

    function getOccurrence(array, value) {
        var count = 0;
        array.forEach((v) => (v === value && count++));
        return count;
    }

    const addToExtraCart = (extra)=>{

        play()

        props.setExtrasCart(current => [...current, extra])
       

   
    }

    const addVariation = (variation) =>{
        play()
        if(props.product.variableProduct && props.hereVariations.length > props.product.variationCount - 1){
            alert('Você atingiu o máximo de opções')
        }
        else {
            props.setHereVariations(current=>[...current, variation])
            // console.log(props.hereVariations)
        }

    }

    const decreaseVariation = (variation)=>{
        play()
        props.hereVariations.splice(props.hereVariations.indexOf(variation), 1)

    }



    const decreaseOneExtra = (extra) =>{
        play()
        props.extrasCart.splice(props.extrasCart.indexOf(extra), 1)
        // console.log(props.extrasCart)

    }

    const addAddOn = (extra) =>{
        // console.log(extra.name.toUpperCase().includes("BORDA"))

        play()
        // console.log(props.product)
        if(props.product.group === "Rapiburguer" || props.product.group === "Porções  " || extra.name.toUpperCase().includes("BORDA")){
            // console.log(extra)
            if(extra.onCart){
                extra.onCart = false
                props.addOnCart.splice(props.addOnCart.indexOf(extra), 1)
            }
            else if(props.extrasCart.length === 1){
                alert('Você atingiu o máximo de adicionais')
            }
            else{
                extra.onCart = true;              
                props.setAddOnCart(current => [...current, extra])
            }

        }
        else{
            // console.log('errou')
            if(extra.onCart){

                extra.onCart = false
                props.extrasCart[props.extrasCart.length - 1]
                    .adicionais.splice(props.extrasCart[props.extrasCart.length - 1]
                    .adicionais.indexOf(extra), 1)
                // props.addOnCart.splice(props.addOnCart.indexOf(extra), 1)
                
    
            }
            else if(props.extrasCart[props.extrasCart.length - 1].adicionais?.length === 3){
                alert('Você atingiu o máximo de adicionais')
            }
            else {
                if(props.extrasCart[props.extrasCart.length - 1].adicionais){
                    extra.onCart = true;
                    let addhere = props.extrasCart[props.extrasCart.length - 1].adicionais
                    addhere.push(extra)
                    props.extrasCart[props.extrasCart.length - 1].adicionais = addhere
    
                    
                }
                else{            
                    extra.onCart = true;
                    props.extrasCart[props.extrasCart.length - 1].adicionais = [extra]
                    // props.setAddOnCart(current => [...current, extra])
                    
                }
            }
        }


    }

    const addOneExtra  = (extra) =>{
        play()


        if(extra.onCart){
            extra.onCart = false
            // props.extrasCart.indexOf(extra).addOns.forEach(add =>{
            //     add.onCart = false
            // })
            props.extrasCart.splice(props.extrasCart.indexOf(extra), 1)

        }
        else if(props.extrasCart.length === limit){
            alert('Você atingiu o máximo de opções')
        }
        else {
            extra.onCart = true
            props.setExtrasCart(current => [...current, extra])
            // console.log(extra)
        }
    }

    const addToCart = (product) =>{
  
        if(props.plusBurger && props.p === ""){
            setMissingPoint(true)
        }

        else if(props.product.flavours.length > 0 && props.p === "" && props.product.flavours[0] !== ""){
            setMissingPoint(true)
        }

        
        else{        

       
        
        props.product.quantity = quantity;

        props.product.subTotal = quantity * props.product.price;

        props.addToCart(product)

        setQuantity(1)


        addOns.forEach(element=>{
            element.onCart = false
        })

        pizzaFlavours.forEach(element=>{
            element.onCart = false
        })

        pizzaBorders.forEach(element=>{
            element.onCart = false
        })
        setObservation('')}
        }
        





    return (
        <div className="product">
        <BiChevronLeftCircle onClick={props.showPmobile}  className="close-mobile"/>
            <img alt="" src={props.product.photo} />
                <h2>{props.product.name}</h2>
                <p>{props.product.short}</p>
                <p>{props.product.long}</p>
                <div className="price-tag-mobile">
                    <p>Valor Unitário R$ {props.product.price ? props.product.price.toFixed(2) : " "}</p>                    
                        
                        
                    </div>
                    <form style={{display: props.varProduct || props.plusBurger? 'flex' : 'none', justifyContent: 'center', alignItems: 'center',flexDirection: 'column', marginBottom: "10px"}}>
                            <label>Escolha o ponto: </label>

                            <select style={{width: '200px'}} value={props.p} onFocus={()=>{setMissingPoint(false)}}onChange={(e)=>props.handleMeatPoint(e.target.value)}>
                                <option value="">-</option>
                                <option value="Mal Passada">Mal Passada</option>
                                <option value="Mal para o ponto">Mal para o ponto</option>
                                <option value="Ao ponto">Ao ponto</option>
                                <option value="Bem Passada">Bem Passada</option>
                            </select>
                            <p style={{display: missingPoint ? 'block' : 'none', color: 'red'}}>escolha o ponto da sua carne</p>
                    </form>

                    <p></p>

                    <form style={{display: props.varProduct ? 'block' : 'none'}}>
                    <p>Acompanhamentos</p>
            {extras.map(extra =>(
                    <div>
                        
                        <div className="extra-span" >
                                    <p style={{marginLeft: '0px', marginTop: '0px'}} >
                                    <IoIosRemove onClick={()=>{decreaseOneExtra(extra)}} style={{ fontSize: '1.5em', marginTop: '0px', marginRight: '5px'}} />
                                    
                                    {getOccurrence(props.extrasCart, extra)}
                                    <GrFormAdd style={{fontSize: '1.5em', marginLeft: '5px'}} onClick={()=>{addToExtraCart(extra)}}  />
                                    
                                </p>
                                {extra.name } <b>R$ {extra.price.toFixed(2)}</b>
                            </div>
                    </div>
                ))} 
              

            </form>
            {hereBorders?.length >= 1 && <Bordas
                data={hereBorders}
                getOccurrence={getOccurrence}
                addToExtraCart={addOneExtra}
                decreaseOneExtra={decreaseOneExtra}
                addAddOn={addAddOn}
                extrasCart={props.extrasCart}
                title={'Bordas'} />

            }

            {hereFlavours?.length >= 1 && <Sabores 
            data={hereFlavours}
            getOccurrence={getOccurrence}
            addToExtraCart={addOneExtra}
            decreaseOneExtra={decreaseOneExtra}
            addOneExtra={addOneExtra}
            extrasCart={props.extrasCart}
            addAddOn={addAddOn}
            title={'Sabores'}
            />}

            {showAddOns  && hereAddOns?.length >= 1 && <Acompanhamentos
            data={hereAddOns}
            getOccurrence={getOccurrence}
            addToExtraCart={addOneExtra}
            decreaseOneExtra={decreaseOneExtra}
            addAddOn={addAddOn}
            extrasCart={props.extrasCart}
            title={'Adicionais'}
            />} 


            <form style={{display: props.plusBurger ? 'block' : 'none'}}>
            <p>Adicionais</p>
            {burgerExtra.map(extra =>(
                    <div>
                        
                        <div className="extra-span" onClick={()=>{addOneExtra(extra)}}>
                        <div><HiCheck style={{color: 'green', fontSize: '1.5em', display: extra.onCart ? 'block' : 'none'}} /></div>
                                {extra.name} <b>R$ {extra.price.toFixed(2)}</b>

                            </div>
                    </div>
                ))} 

            </form>

            <form style={{display: props.sushiExtra ? 'block' : 'none'}}>
            <p>Adicionais</p>
            {sushiExtra.map(extra =>(
                    <div>
                        
                        <div className="extra-span" >
                                    <p style={{marginLeft: '0px', marginTop: '0px'}} >
                                    <IoIosRemove onClick={()=>{decreaseOneExtra(extra)}} style={{ fontSize: '1.5em', marginTop: '0px', marginRight: '5px'}} />
                                    {getOccurrence(props.extrasCart, extra)}
                                    <GrFormAdd style={{fontSize: '1.5em', marginLeft: '5px'}} onClick={()=>{addToExtraCart(extra)}}  />
                                    
                                </p>
                                {extra.name } <b>R$ {extra.price.toFixed(2)}</b>
                            </div>
                    </div>
                ))} 

            </form>

            <form style={{display: props.varProduct ? 'block' : 'none'}}>
            <p>Adicionais</p>
            {extraAdds.map(extra =>(
                    <div>
                        
                        <div className="extra-span"  >
                        {/* <div><HiCheck style={{color: 'green', fontSize: '1.5em', display: extra.onCart ? 'block' : 'none'}} /></div> */}
                                <p style={{marginLeft: '0px', marginTop: '0px'}}>
                                    <IoIosRemove onClick={()=>{decreaseOneExtra(extra)}} style={{ fontSize: '1.5em', marginTop: '0px', marginRight: '5px'}} />
                                    {/* {getOccurrence(props.extrasCart, extra)} */}
                                    {getOccurrence(props.extrasCart, extra)}
                                    <GrFormAdd style={{fontSize: '1.5em', marginLeft: '5px'}} onClick={()=>{addToExtraCart(extra)}}  />
                                    
                                </p>
                                <div >{extra.name}</div> <b>R$ {extra.price.toFixed(2)}</b>
                                
                            </div>
                    </div>
                ))} 

            </form>
            <form className="obs-product" style={{display: props.plusBurger ? 'flex' : 'none'}}>
                
                <label>Observação</label>
                <input type="text" value={props.observation} placeholder="Ex. Sem cebola..." onChange={(e)=>{handleObs(e.target.value)}}></input>
            </form>

            <p style={{textDecoration: props.oldPrice ? 'line-through': 'none', fontSize: '0.8em'}}>{props.oldPrice ? 'R$' + props.oldPrice.toFixed(2): ''}  </p>


                <div className="flex-row">
                        <div className="product-mobile-quantity">                            
                            <IoIosRemove style={{fontSize: '1.5em', marginRight: '5px'}} onClick={decreaseOne}   />
                            <input type="number" style={{height: '20px'}} value={quantity} />
                            <GrFormAdd style={{fontSize: '1.5em', marginLeft: '5px'}} onClick={addOne}  />

                        </div>
                    
                    <h4 style={{marginTop: "30px", textAlign: 'right', marginRight: "20px"}}> {'subtotal R$ ' + (props.subtotal * quantity).toFixed(2)}</h4>
                    </div>

                    <span style={buttonStyle} onClick={()=>{addToCart(props.product)}}
                   >Adicionar ao pedido</span>
                                               
            
        </div>
    )
}

const buttonStyle = {
    alignSelf: 'center', 
    justifySelf: 'flex-end',
    // position: 'fixed',
    bottom: '5px',
    backgroundColor: "#CA3B00",
    color: "white",
    padding: "10px 20px",
    marginBottom: "20px"
}