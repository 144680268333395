import {React, useEffect, useState} from 'react';
import './css/checkout.css';
import './components.css';



import { IoIosCloseCircle } from "react-icons/io";


export default function Checkout(props) {

    const handleAlertMinimum = () =>{
        alert('Pedido mínimo R$ 30,00!')
    }

    const HandleMinimumValue = () =>{
   
            return <span onClick={props.handleCloseOrder} >Finalizar Pedido</span>

    }

    const HandleHours = () =>{
        var date = new Date()

        // if(props.shop[0]){
        //     if(props.shop[0].isOpen){
        //         switch (date.getDay()) {
        //             case 1:
        //                 return <p style={{color: 'red'}}>Fechado Agora <br></br> <br></br> </p>
                        
        //             case 0:                    
        //             case 2:
        //             case 3:       
        //                 if(date.getHours() < 12 && date.getHours() > 0 ){
        //                     return <p style={{color: 'red'}}>Fechado Agora <br></br> <br></br> </p>
        //                 }            
        //                 else {
                            
        //                     return <HandleMinimumValue />
        //                 }
        //                 case 4:
        //                 case 5:
        //                 case 6:
                            
        //                         if(date.getHours() < 12 && date.getHours() > 2 ){
        //                         return <p style={{color: 'red'}}>Fechado Agora <br></br> <br></br> </p>
        //                     }            
        //                     else {
                                
        //                         return <HandleMinimumValue />
        //                     }


                
        //             default:
        //                 if(date.getHours() < 10 && date.getHours() > 1){
        //                     return <p style={{color: 'red'}}>Estamos fechados <br></br> <br></br> </p>
        //                 }            
        //                 else {
                            
        //                     return <HandleMinimumValue />
        //                 }
        //         }


        //     }    
        //     else{
        //         return <p style={{color: 'red'}}>Estamos fechados <br></br>Volte de terça a sábado <br></br> entre 18:30 e 23:00</p>
        //     }           
        // }

        // else return null;

        return <HandleMinimumValue />



    }

    console.log(props.cart)

    return (
        <div>
                    <div className="checkout">
                        
                        <IoIosCloseCircle onClick={props.foo} className="adddecbutton modalclose"/>
                        <HandleHours />
                        <h4>Total - R$ {props.total.toFixed(2)}</h4>
                        {/* implement table of cart products */}
                        {props.cart? props.cart.map(cart=>(
                            <div className="cart-item" >
                                <p>{cart.quantity + 'x'}</p>
                                <div className="flex-column" style={{width: '215px'}}>
                                    <p><b>{cart.name}</b></p>
                                    <div className="checkout-extras">
                                    {cart.extrasCart.map(extra=>(
                                        <>
                                            <div className="flex-row" style={{marginBottom: '-10px', marginTop: '0px'}}>
                                                <p style={{lineHeight: '18px',fontSize: '0.7em' }}key={extra.quantity}>{extra.quantity + 'x'}</p>
                                                <p style={{marginBottom: "-20px", lineHeight: '18px', marginRight: '-30px', fontSize: '0.7em'}} key={extra.name}>{extra.name}</p>
                                                <p style={{marginLeft: '25px', lineHeight: '18px', marginRight: '-20px', fontSize: '0.7em'}}>{'R$ '+(extra.price * extra.quantity).toFixed(2)}</p>
                                                
                                                <IoIosCloseCircle style={{color: '#fc4041', fontSize: '1em', marginTop: '15px', marginLeft: '20px', lineHeight: '10px'}} 
                                                onClick={()=>{props.removeExtra(cart, cart.extrasCart, extra)}}
                                                />
                                                
                                            </div>
                                            {extra.adicionais?.map(adicional =>(
                                                <p style={{lineHeight: '12px',fontSize: '0.7em', margin: '0', paddingLeft: "20px" }}>{"adicionar " + adicional.name}</p>
                                            ))}
                                        </>

                                            ))}
                                    </div>
                                    {cart.addOnCart?.length > 0 && <div className="checkout-extras" style={{margin: '0'}}>
                                        <p style={{lineHeight: '08px',fontSize: '0.7em', marginBottom: '-18px', marginTop: '20px' }}><b>Acompanha</b></p>
                                    {cart.addOnCart?.map(extra=>(
                                            <div className="flex-row" style={{marginBottom: '-30px', marginTop: '0px'}}>
                                                <p style={{lineHeight: '18px',fontSize: '0.7em' }}key={extra.quantity}>{extra.quantity + 'x'}</p>
                                                <p style={{marginBottom: "-20px", lineHeight: '18px', marginRight: '-30px', fontSize: '0.7em'}} key={extra.name}>{extra.name}</p>
                                                <p style={{marginLeft: '25px', lineHeight: '18px', marginRight: '-20px', fontSize: '0.7em'}}>{'R$ '+(extra.price * extra.quantity).toFixed(2)}</p>
                                                
                                                <IoIosCloseCircle style={{color: '#fc4041', fontSize: '1em', marginTop: '15px', marginLeft: '20px', lineHeight: '10px'}} 
                                                onClick={()=>{props.removeAddOn(cart, cart.addOnCart, extra)}}
                                                />
                                                
                                            </div>
                                            ))}
                                    </div> }
                                    <p style={{marginBottom: '-20px'}}><em>{cart.observation}</em></p>
                                    <p style={{marginTop: '20px'}}><em>{cart.point}</em></p>
                                    <p style={{marginTop: '0px'}}><em>{cart.firstFlavour}</em></p>
                                    <p style={{marginTop: '-20px'}}><em>{cart.secondFlavour}</em></p>
                                    <p style={{marginTop: '-20px'}}><em>{cart.thirdFlavour}</em></p>
                                </div>
                                
                                <p> <b>R$ 
                                    {cart.subTotal ? (parseFloat(cart.subTotal) + parseFloat(cart.extrasPrice) + parseFloat(cart.addOnPrice)).toFixed(2) : 0}</b> 
                                    <IoIosCloseCircle style={{color: '#fc4041', fontSize: '1.4em'}} 
                                    onClick={()=>{props.remove(cart)}}
                                    
                                    /></p>
                                
                            </div>
                        )) : ''} 

                            
                        </div>
        </div>
    )
}