import {React, useEffect, useState} from 'react'
import axios from 'axios'

export default function Routes(props) {
    const [routes, setRoutes] = useState([])
    useEffect(() => {
        axios.get(props.apiUrl + 'deliveryRoute')
            .then(res=> setRoutes(res.data))
            .catch(err=>console.error(err))
    }, [])

    const handleRouteModal = () =>{
        props.setSchedule(false)
    }

    const fraiburgo = routes.filter(route=>{
        if(route.city === 'Fraiburgo'){
            return route
        }
    })

    const herval = routes.filter(route=>{
        if(route.city === "Herval d' Oeste"){
            return route
        }
    })

    const luzerna = routes.filter(route=>{
        if(route.city === 'Luzerna'){
            return route
        }
    })




    return (
        <div onClick={handleRouteModal} style={{
            display: 'flex',
            flexDirection: 'column',
            width: '90vw',
            minHeight: '90vh',
            backgroundColor: '#f9f9f9',
            borderRadius: '10px',
            paddingBottom: '30px',
            marginTop: '20px',
            marginBottom: '20px',
            color: '#404355'
        }}>

            <h3>Fraiburgo</h3>
            
            {fraiburgo.map(route=>(
                
                <div className="flex-row" style={{width: '90%', justifyContent: 'space-between', marginLeft: '15px', marginBottom: '-20px', fontSize: '0.8em'}}>
                    <p key={route.name}>{route.name}</p>
                    <p style={{color: '#CA3B00'}} key={route.cost}>{route.cost ? 'R$ ' + route.cost.toFixed(2) : ''}</p>
                </div>
            ))}

           

            
        </div>
    )
}
