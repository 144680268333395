import React from 'react'
import {ReactComponent as PizzaFlavourOne} from '../../assets/pizzaflavour1.svg'
import {ReactComponent as PizzaFlavourTwo} from '../../assets/pizzaflavour2.svg'
import { ReactComponent as PizzaFlavourThree } from '../../assets/pizzaflavour3.svg'

export default function FlavourDisplay({productName, setLimit, limit, setMultiplier, multiplier, pizzaFlavours, setExtrasCart}) {



    const handleMultiplier = (limit, multiplier) =>{
        setLimit(limit)
        setMultiplier(multiplier)
        pizzaFlavours.forEach(item=>{
            item.onCart = false
        })
        setExtrasCart([])
    }
  return (
    <>
        {productName === "Pizza P" && <div className='flavourChoice' >
                <div
                    className={limit === 1 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(1, 2)}
                >
                    <PizzaFlavourOne />
                    <p>1 sabor</p>
                </div>
                <div
                    className={limit === 2 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(2, 1)}
                >
                    <PizzaFlavourTwo />
                    2 sabores
                </div>

            </div>
            }

            {productName === "Calzone G" && <div className='flavourChoice' >
                <div
                    className={limit === 1 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(1, 2)}
                >
                    <PizzaFlavourOne />
                    <p>1 sabor</p>
                </div>
                <div
                    className={limit === 2 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(2, 1)}
                >
                    <PizzaFlavourTwo />
                    2 sabores
                </div>

            </div>
            }
                {productName === "Calzone F" && <div className='flavourChoice' >
                <div
                    className={limit === 1 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(1, 2)}
                >
                    <PizzaFlavourOne />
                    <p>1 sabor</p>
                </div>
                <div
                    className={limit === 2 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(2, 1)}
                >
                    <PizzaFlavourTwo />
                    2 sabores
                </div>

            </div>
            }

            {productName === "Pizza M" && <div className='flavourChoice'>
                <div
                    className={limit === 1 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(1, 2)}
                >
                    <PizzaFlavourOne />
                    <p>1 sabor</p>
                </div>
                <div
                    className={limit === 2 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(2, 1)}
                >
                    <PizzaFlavourTwo />
                    2 sabores
                </div>
            </div>
            }
            
            {productName === "Pizza G" && <div className='flavourChoice'>
                <div
                    className={limit === 1 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(1, 3)}
                >
                    <PizzaFlavourOne />
                    <p>1 sabor</p>
                </div>
                <div
                    className={limit === 2 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(2, 2)}
                >
                    <PizzaFlavourTwo />
                    2 sabores
                </div>
                <div
                    className={limit === 3 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(3, 1)}
                >
                    <PizzaFlavourThree />
                    3 sabores
                </div>
            </div>
            }

            {productName === "Pizza F" && <div className='flavourChoice' style={{flexWrap: 'wrap'}}>
                <div
                    className={limit === 1 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(1, 4)}
                >
                    <PizzaFlavourOne />
                    <p>1 sabor</p>
                </div>
                <div
                    className={limit === 2 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(2, 2)}
                >
                    <PizzaFlavourTwo />
                    2 sabores
                </div>
                <div
                    className={limit === 3 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(3, 1.5)}
                >
                    <PizzaFlavourThree />
                    3 sabores
                </div>
                <div
                    className={limit === 4 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(4, 1)}
                >
                    
                   <span style={limit === 4 ? btnChosen : btnFlavour} >4 sabores</span>
                </div>
            </div>
            }
            {productName === "Pizza Meio Metro" && <div className='flavourChoice' style={{flexWrap: 'wrap'}}>
                <div
                    className={limit === 1 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(1, 5)}
                >
                    <PizzaFlavourOne />
                    <p>1 sabor</p>
                </div>
                <div
                    className={limit === 2 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(2, 2.5)}
                >
                    <PizzaFlavourTwo />
                    2 sabores
                </div>
                <div
                    className={limit === 3 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(3, 1.6)}
                >
                    <PizzaFlavourThree />
                    3 sabores
                </div>
                <div
                    className={limit === 4 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(4, 1.25)}
                >
                    
                   <span style={limit === 4 ? btnChosen : btnFlavour}>4 sabores</span>
                </div>
                <div
                    className={limit === 5 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(5, 1)}
                >
                    
                   <span style={limit === 5 ? btnChosen : btnFlavour}>5 sabores</span>
                </div>
            </div>

            }
                {productName === "Pizza Metro" && <div className='flavourChoice' style={{flexWrap: 'wrap'}}>
                <div
                    className={limit === 1 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(1, 8)}
                >
                    <PizzaFlavourOne />
                    <p>1 sabor</p>
                </div>
                <div
                    className={limit === 2 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(2, 4)}
                >
                    <PizzaFlavourTwo />
                    2 sabores
                </div>
                <div
                    className={limit === 3 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(3, 2.6)}
                >
                    <PizzaFlavourThree />
                    3 sabores
                </div>
                <div
                    className={limit === 4 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(4, 2)}
                >
                    
                   <span style={limit === 4 ? btnChosen : btnFlavour}>4 </span>
                </div>
                <div
                    className={limit === 5 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(5, 1.6)}
                >
                    
                   <span style={limit === 5 ? btnChosen : btnFlavour}>5 </span>
                </div>
                <div
                    className={limit === 6 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(6, 1.3)}
                >
                    
                   <span style={limit === 6 ? btnChosen : btnFlavour}>6 </span>
                </div>
                <div
                    className={limit === 7 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(7, 1.14)}
                >
                    
                   <span style={limit === 7 ? btnChosen : btnFlavour}>7 </span>
                </div>
                <div
                    className={limit === 8 ? 'flavourChosen' : ''}
                    onClick={()=>handleMultiplier(8, 1)}
                >
                    
                   <span style={limit === 8 ? btnChosen : btnFlavour}>8 </span>
                </div>
            </div>
            }
    </>
  )
}

const btnFlavour ={
    border: '1px solid grey',
    backgroundColor: 'white',
    color: 'grey',
    marginTop: '20px',
    marginBottom: '0px',
    minWidth: '30px'
}

const btnChosen ={
    marginTop: '20px',
    marginBottom: '0px',
    minWidth: '30px'
}