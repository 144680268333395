import React from 'react'
import {BiChevronLeftCircle} from 'react-icons/bi'
import {HiCheck} from 'react-icons/hi'
import {IoIosRemove, IoIosCloseCircle} from 'react-icons/io'
import {GrFormAdd} from 'react-icons/gr'
import { FiCheckSquare, FiSquare } from 'react-icons/fi'


export default function Sabores({title,addAdicionais, data, getOccurrence, addOneExtra, decreaseOneExtra, extrasCart, addToExtraCart, addAddOn, multiplier}) {

  const sort = (property) =>{
    // console.log(property)

    const sorted = []
    
    property.map(product=>{
        if(sorted.indexOf(product.subGroup) === -1){
           sorted.push(product.subGroup)
        }
    })

    return sorted;
}

const sorted = sort(data)

 

  return (
    <>
      <p>{title}</p>
      {sorted.map(sub=>(
        
        <>
          <p>{sub}</p>
          <FilterFlavour addAdicionais={addAdicionais} sub={sub} data={data} extrasCart={extrasCart} decreaseOneExtra={decreaseOneExtra} addOneExtra={addOneExtra} addAddOn={addAddOn} multiplier={multiplier} />
        </>
      ))}

    </>
  )
}

export const FilterFlavour = ({addOneExtra, addAdicionais, decreaseOneExtra, title, data, sub, addAddOn, multiplier, extrasCart})=>{

  const [count, setCount] = React.useState(0)


  const getOccurrence = (array, value) => {
    var count = 0;
    array.forEach((v) => (v.name === value.name && count++));
    return count;
}



    const menu = data.filter(item=>{
     
      if(sub === item.subGroup){
        // console.log(item)
        
        return item
      }
    })

    // React.useEffect(()=>{
    //   menu.forEach(extra=>{
    //     extra.price = extra.price * multiplier
    //   })
  
    // }, [multiplier])



    const addToFlavour = (extra, addOn) =>{
      setCount(count + 1)
      // addOn.onCart = true
      // addAddOn(addOn)
      addAdicionais(addOn, extra)
    }
  return (
    <div style={{padding: " 0 30px"}}>
    <form>
      
          {menu?.map(extra =>(
            
                  <div>                      
                    <div className="extra-span"  >
                        <div style={{display: "flex",flexDirection: "row"}}>
                        <IoIosRemove onClick={()=>{decreaseOneExtra(extra)}} style={{ fontSize: '1.5em', marginTop: '0px', marginRight: '5px'}} />
                                    
                                    {getOccurrence(extrasCart, extra)}
                        <GrFormAdd style={{fontSize: '1.5em', marginLeft: '5px'}} onClick={()=>{addOneExtra(extra)}}  />
                                    
                        </div>

                        <div>
                          <span onClick={()=>{addOneExtra(extra)}}>{extra.name} {'R$ ' + (extra.price * multiplier).toFixed(2)}</span>
                          <span>{extra.short}</span>
                          {extra.onCart && extra.addOns.length > 0 && <div style={styles.addOnBox}>
                              <p style={{margin: '0'}}><b>escolha até 3 adicionais</b></p>
                            {extra.addOns?.map(addOn=>(
                                <div style={styles.addOnBox.addOn} onClick={()=>addToFlavour(extra, addOn)}>                                  
                                  <FiSquare style={{display: !addOn.onCart ? 'block' : 'none'}} />  <FiCheckSquare style={{display: addOn.onCart ? 'block' : 'none'}} />  &nbsp; {addOn.name}           

                                </div>
                            ))}
                            <span style={{width: '100%', height: '20px'}}></span>
                          </div> 
                          }     
              
                          
                        </div>
                        
                      </div>
                  </div>
              ))} 

          </form>
  </div>
  )
}

const styles ={
  addOnBox:{
    width: '100%',
    minHeight: '40px',
    // backgroundColor: 'green',

    addOn:{
      display: 'flex',
      flexDirection: 'row',
      // fontSize: '0.8em',
      fontWeight: '300',
      alignItems: 'center'
      // lineHeight: '100%',
      // margin: '0'
    }

  }
}