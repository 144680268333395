export const bordasP = [
    {
        "_id": "63c59e89088af8f04dc022f3",
        "code": 1,
        "name": "BORDA DE CHEDDAR",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91e8921bb339c2e67acbe",
        "code": 2,
        "name": "Borda De Catupiry",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91e9821bb339c2e67acfc",
        "code": 3,
        "name": "Borda de Queijo",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91ea421bb339c2e67ad32",
        "code": 4,
        "name": "Borda de Mussarela",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91eb521bb339c2e67ad7e",
        "code": 6,
        "name": "Borda de 4 Queijos",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91ecb21bb339c2e67ade1",
        "code": 6,
        "name": "Borda de Presunto",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91ed821bb339c2e67ae1b",
        "code": 7,
        "name": "Borda de Brócolis",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643def57dd2fc8978f5e4607",
        "code": 11,
        "name": "Borda de Calabresa",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df0aedd2fc8978f5e51ca",
        "code": 110,
        "name": "Borda de Bacon",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df0e9dd2fc8978f5e53ce",
        "code": 111,
        "name": "Borda de Frango",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df11fdd2fc8978f5e55a6",
        "code": 112,
        "name": "Borda de Salsicha",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df141dd2fc8978f5e56d8",
        "code": 113,
        "name": "Borda de Chocolate Branco ",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df168dd2fc8978f5e582f",
        "code": 114,
        "name": "Borda de Chocolate Preto ",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1a6dd2fc8978f5e5a4e",
        "code": 114,
        "name": "Borda de Sensação",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1bfdd2fc8978f5e5b20",
        "code": 115,
        "name": "Borda de Prestígio",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1cedd2fc8978f5e5ba7",
        "code": 116,
        "name": "Borda de Confete",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1dddd2fc8978f5e5c2f",
        "code": 117,
        "name": "Borda de Choquito ",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1efdd2fc8978f5e5cd0",
        "code": 118,
        "name": "Borda de Doce de Leite",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df200dd2fc8978f5e5d53",
        "code": 119,
        "name": "Borda de Churros",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df221dd2fc8978f5e5e67",
        "code": 120,
        "name": "Borda de Marshimallow",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df236dd2fc8978f5e5f14",
        "code": 121,
        "name": "Borda de Banana",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df24cdd2fc8978f5e5fd2",
        "code": 122,
        "name": "Borda de Pé de Moleque",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df25fdd2fc8978f5e606d",
        "code": 123,
        "name": "Borda de Suspiro",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df272dd2fc8978f5e6109",
        "code": 124,
        "name": "Borda de Bis",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df28fdd2fc8978f5e6205",
        "code": 125,
        "name": "Borda de Nutella ",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2abdd2fc8978f5e62e9",
        "code": 126,
        "name": "Borda de Bombom",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2c4dd2fc8978f5e63c7",
        "code": 127,
        "name": "Borda de Baunilha ",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2d9dd2fc8978f5e6478",
        "code": 129,
        "name": "Borda de Casadinho",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2f0dd2fc8978f5e6540",
        "code": 130,
        "name": "Borda de Paçoca",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df307dd2fc8978f5e660c",
        "code": 131,
        "name": "Borda de Beijinho",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df31add2fc8978f5e66b7",
        "code": 132,
        "name": "Borda de Brigadeiro ",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df332dd2fc8978f5e6784",
        "code": 133,
        "name": "Borda de Waffer ",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df344dd2fc8978f5e6828",
        "code": 134,
        "name": "Borda de Negresco",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df359dd2fc8978f5e68df",
        "code": 135,
        "name": "Borda de Shot",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df359dd2fc8978f5e6asdfadff",
        "code": 136,
        "name": "Borda de Cookies",
        "price": 10,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    }
]

export const bordasM = [
    {
        "_id": "63c59e89088af8f04dc022f3",
        "code": 1,
        "name": "BORDA DE CHEDDAR",
        "price": 13,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91e8921bb339c2e67acbe",
        "code": 2,
        "name": "Borda De Catupiry",
        "price": 13,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91e9821bb339c2e67acfc",
        "code": 3,
        "name": "Borda de Queijo",
        "price": 13,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91ea421bb339c2e67ad32",
        "code": 4,
        "name": "Borda de Mussarela",
        "price": 13,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91eb521bb339c2e67ad7e",
        "code": 6,
        "name": "Borda de 4 Queijos",
        "price": 13,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91ecb21bb339c2e67ade1",
        "code": 6,
        "name": "Borda de Presunto",
        "price": 13,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91ed821bb339c2e67ae1b",
        "code": 7,
        "name": "Borda de Brócolis",
        "price": 13,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643def57dd2fc8978f5e4607",
        "code": 11,
        "name": "Borda de Calabresa",
        "price": 13,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df0aedd2fc8978f5e51ca",
        "code": 111,
        "name": "Borda de Bacon",
        "price": 13,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df0e9dd2fc8978f5e53ce",
        "code": 111,
        "name": "Borda de Frango",
        "price": 13,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df11fdd2fc8978f5e55a6",
        "code": 112,
        "name": "Borda de Salsicha",
        "price": 13,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df141dd2fc8978f5e56d8",
        "code": 113,
        "name": "Borda de Chocolate Branco ",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df168dd2fc8978f5e582f",
        "code": 114,
        "name": "Borda de Chocolate Preto ",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1a6dd2fc8978f5e5a4e",
        "code": 114,
        "name": "Borda de Sensação",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1bfdd2fc8978f5e5b20",
        "code": 115,
        "name": "Borda de Prestígio",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1cedd2fc8978f5e5ba7",
        "code": 116,
        "name": "Borda de Confete",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1dddd2fc8978f5e5c2f",
        "code": 117,
        "name": "Borda de Choquito ",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1efdd2fc8978f5e5cd0",
        "code": 118,
        "name": "Borda de Doce de Leite",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df200dd2fc8978f5e5d53",
        "code": 119,
        "name": "Borda de Churros",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df221dd2fc8978f5e5e67",
        "code": 120,
        "name": "Borda de Marshimallow",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df236dd2fc8978f5e5f14",
        "code": 121,
        "name": "Borda de Banana",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df24cdd2fc8978f5e5fd2",
        "code": 122,
        "name": "Borda de Pé de Moleque",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df25fdd2fc8978f5e606d",
        "code": 123,
        "name": "Borda de Suspiro",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df272dd2fc8978f5e6119",
        "code": 124,
        "name": "Borda de Bis",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df28fdd2fc8978f5e6205",
        "code": 125,
        "name": "Borda de Nutella ",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2abdd2fc8978f5e62e9",
        "code": 126,
        "name": "Borda de Bombom",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2c4dd2fc8978f5e63c7",
        "code": 127,
        "name": "Borda de Baunilha ",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2d9dd2fc8978f5e6478",
        "code": 129,
        "name": "Borda de Casadinho",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2f0dd2fc8978f5e6540",
        "code": 130,
        "name": "Borda de Paçoca",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df307dd2fc8978f5e660c",
        "code": 131,
        "name": "Borda de Beijinho",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df31add2fc8978f5e66b7",
        "code": 132,
        "name": "Borda de Brigadeiro ",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df332dd2fc8978f5e6784",
        "code": 133,
        "name": "Borda de Waffer ",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df344dd2fc8978f5e6828",
        "code": 134,
        "name": "Borda de Negresco",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df359dd2fc8978f5e68df",
        "code": 135,
        "name": "Borda de Shot",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df359dd2rqrqrqrq978f5e6asdfadff",
        "code": 136,
        "name": "Borda de Cookies",
        "price": 11,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    }
]

export const bordasG = [
    {
        "_id": "63c59e89088af8f04dc022f3",
        "code": 1,
        "name": "BORDA DE CHEDDAR",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91e8921bb339c2e67acbe",
        "code": 2,
        "name": "Borda De Catupiry",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91e9821bb339c2e67acfc",
        "code": 3,
        "name": "Borda de Queijo",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91ea421bb339c2e67ad32",
        "code": 4,
        "name": "Borda de Mussarela",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91eb521bb339c2e67ad7e",
        "code": 6,
        "name": "Borda de 4 Queijos",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91ecb21bb339c2e67ade1",
        "code": 6,
        "name": "Borda de Presunto",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91ed821bb339c2e67ae1b",
        "code": 7,
        "name": "Borda de Brócolis",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643def57dd2fc8978f5e4607",
        "code": 11,
        "name": "Borda de Calabresa",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df0aedd2fc8978f5e51ca",
        "code": 112,
        "name": "Borda de Bacon",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df0e9dd2fc8978f5e53ce",
        "code": 111,
        "name": "Borda de Frango",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df11fdd2fc8978f5e55a6",
        "code": 112,
        "name": "Borda de Salsicha",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df141dd2fc8978f5e56d8",
        "code": 113,
        "name": "Borda de Chocolate Branco ",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df168dd2fc8978f5e582f",
        "code": 114,
        "name": "Borda de Chocolate Preto ",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1a6dd2fc8978f5e5a4e",
        "code": 114,
        "name": "Borda de Sensação",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1bfdd2fc8978f5e5b20",
        "code": 115,
        "name": "Borda de Prestígio",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1cedd2fc8978f5e5ba7",
        "code": 116,
        "name": "Borda de Confete",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1dddd2fc8978f5e5c2f",
        "code": 117,
        "name": "Borda de Choquito ",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1efdd2fc8978f5e5cd0",
        "code": 118,
        "name": "Borda de Doce de Leite",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df200dd2fc8978f5e5d53",
        "code": 119,
        "name": "Borda de Churros",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df221dd2fc8978f5e5e67",
        "code": 120,
        "name": "Borda de Marshimallow",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df236dd2fc8978f5e5f14",
        "code": 121,
        "name": "Borda de Banana",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df24cdd2fc8978f5e5fd2",
        "code": 122,
        "name": "Borda de Pé de Moleque",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df25fdd2fc8978f5e606d",
        "code": 123,
        "name": "Borda de Suspiro",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df272dd2fc8978f5e6129",
        "code": 124,
        "name": "Borda de Bis",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df28fdd2fc8978f5e6205",
        "code": 125,
        "name": "Borda de Nutella ",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2abdd2fc8978f5e62e9",
        "code": 126,
        "name": "Borda de Bombom",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2c4dd2fc8978f5e63c7",
        "code": 127,
        "name": "Borda de Baunilha ",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2d9dd2fc8978f5e6478",
        "code": 129,
        "name": "Borda de Casadinho",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2f0dd2fc8978f5e6540",
        "code": 130,
        "name": "Borda de Paçoca",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df307dd2fc8978f5e660c",
        "code": 131,
        "name": "Borda de Beijinho",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df31add2fc8978f5e66b7",
        "code": 132,
        "name": "Borda de Brigadeiro ",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df332dd2fc8978f5e6784",
        "code": 133,
        "name": "Borda de Waffer ",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df344dd2fc8978f5e6828",
        "code": 134,
        "name": "Borda de Negresco",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df359dd2fc8978f5e68df",
        "code": 135,
        "name": "Borda de Shot",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df359dd2fc8978aoepeosdfadff",
        "code": 136,
        "name": "Borda de Cookies",
        "price": 12,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    }
]

export const bordasF = [
    {
        "_id": "63c59e89088af8f04dc022f3",
        "code": 1,
        "name": "BORDA DE CHEDDAR",
        "price": 16,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91e8921bb339c2e67acbe",
        "code": 2,
        "name": "Borda De Catupiry",
        "price": 16,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91e9821bb339c2e67acfc",
        "code": 3,
        "name": "Borda de Queijo",
        "price": 16,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91ea421bb339c2e67ad32",
        "code": 4,
        "name": "Borda de Mussarela",
        "price": 16,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91eb521bb339c2e67ad7e",
        "code": 6,
        "name": "Borda de 4 Queijos",
        "price": 16,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91ecb21bb339c2e67ade1",
        "code": 6,
        "name": "Borda de Presunto",
        "price": 16,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "63d91ed821bb339c2e67ae1b",
        "code": 7,
        "name": "Borda de Brócolis",
        "price": 16,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643def57dd2fc8978f5e4607",
        "code": 11,
        "name": "Borda de Calabresa",
        "price": 16,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df0aedd2fc8978f5e51ca",
        "code": 114,
        "name": "Borda de Bacon",
        "price": 16,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df0e9dd2fc8978f5e53ce",
        "code": 111,
        "name": "Borda de Frango",
        "price": 16,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df11fdd2fc8978f5e55a6",
        "code": 112,
        "name": "Borda de Salsicha",
        "price": 16,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df141dd2fc8978f5e56d8",
        "code": 113,
        "name": "Borda de Chocolate Branco ",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df168dd2fc8978f5e582f",
        "code": 114,
        "name": "Borda de Chocolate Preto ",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1a6dd2fc8978f5e5a4e",
        "code": 114,
        "name": "Borda de Sensação",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1bfdd2fc8978f5e5b20",
        "code": 115,
        "name": "Borda de Prestígio",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1cedd2fc8978f5e5ba7",
        "code": 116,
        "name": "Borda de Confete",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1dddd2fc8978f5e5c2f",
        "code": 117,
        "name": "Borda de Choquito ",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df1efdd2fc8978f5e5cd0",
        "code": 118,
        "name": "Borda de Doce de Leite",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df200dd2fc8978f5e5d53",
        "code": 119,
        "name": "Borda de Churros",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df221dd2fc8978f5e5e67",
        "code": 120,
        "name": "Borda de Marshimallow",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df236dd2fc8978f5e5f14",
        "code": 121,
        "name": "Borda de Banana",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df24cdd2fc8978f5e5fd2",
        "code": 122,
        "name": "Borda de Pé de Moleque",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df25fdd2fc8978f5e606d",
        "code": 123,
        "name": "Borda de Suspiro",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df272dd2fc8978f5e6149",
        "code": 124,
        "name": "Borda de Bis",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df28fdd2fc8978f5e6205",
        "code": 125,
        "name": "Borda de Nutella ",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2abdd2fc8978f5e62e9",
        "code": 126,
        "name": "Borda de Bombom",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2c4dd2fc8978f5e63c7",
        "code": 127,
        "name": "Borda de Baunilha ",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2d9dd2fc8978f5e6478",
        "code": 129,
        "name": "Borda de Casadinho",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df2f0dd2fc8978f5e6540",
        "code": 130,
        "name": "Borda de Paçoca",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df307dd2fc8978f5e660c",
        "code": 131,
        "name": "Borda de Beijinho",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df31add2fc8978f5e66b7",
        "code": 132,
        "name": "Borda de Brigadeiro ",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df332dd2fc8978f5e6784",
        "code": 133,
        "name": "Borda de Waffer ",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df344dd2fc8978f5e6828",
        "code": 134,
        "name": "Borda de Negresco",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df359dd2fc8978f5e68df",
        "code": 135,
        "name": "Borda de Shot",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    },
    {
        "_id": "643df359dd2fc8978f5e6asdfadff",
        "code": 136,
        "name": "Borda de Cookies",
        "price": 14,
        "onCart": false,
        "category": "Pizzas",
        "__v": 0
    }
]
