import {React, useRef} from 'react'
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { BiBeer, BiDrink } from "react-icons/bi";
import {GiCigar, GiWaterBottle} from "react-icons/gi"
import {ReactComponent as Vodka} from "../assets/vodka.svg"
import {ReactComponent as Cola} from "../assets/cola.svg"
import {ReactComponent as Coco} from "../assets/coco.svg"
import {ReactComponent as Combo} from "../assets/combo.svg"
import {ReactComponent as Porcao} from "../assets/portion.svg"
import {ReactComponent as Corote} from "../assets/corote.svg"
import {ReactComponent as Sobremesa} from "../assets/sobremesa.svg"
import {ReactComponent as Leaves} from "../assets/leaves.svg"
import {ReactComponent as Burger} from "../assets/burger.svg"
import { ReactComponent as Energetico } from '../assets/energetico.svg';
import {ReactComponent as Soda} from '../assets/soda.svg'
import {ReactComponent as Pizza} from '../assets/Pizza.svg'
import {ReactComponent as Esfiha} from '../assets/esfiha.svg'
import {ReactComponent as Pastel} from '../assets/pastel.svg'
import {ReactComponent as HotDog} from '../assets/hotdog.svg'
import {ReactComponent as Panqueca} from '../assets/panqueca.svg'


import raango from '../assets/logo2.png'
import './css/nav-mobile.css'

import {scroller, animateScroll} from 'react-scroll'

import {
    BrowserRouter as Router,
    Link


  } from "react-router-dom";
import Banner from './components.utils/Banner';


export default function NavMobile(props) {

    const myRef = useRef(null)

    
    
    const sort = require('../utils/sort').sort

    const products = props.productsDB.filter(prod=> {
  
            return prod;
        

    })

    const subcategories = sort(products)



    const iconMenuClick = (menu) =>{
        myRef?.current?.scrollIntoView()
        props.setConvProps(menu)
        props.setShowPromo(false)
        props.handleTabClick(menu)
        props.handleScroll()
    }

    const addPromo = () =>{
        var borbaBurger = {

            "code": 33,
            "name": "Combo 03",
            "short": "60 peças",
            "long": "Filadelphia de salmão(10un), Sashimi de salmão(10un), Folha de Arroz, salmão, palmito, cheese e cebolinha(10un), Folha de Arroz, salmão, atum, cheese e cebolinha(10un), Niguiri de Salmão(10un), Gunkan jow(10un)",
            "photo": "http://efraimjer.tk/deborba/app/combo3.jpg",
            "pontuation": null,
            "price": 149.9,
            "isAvailable": true,
            "table": "PADRÃO",
            "mode": "Todos",
            "group": "Combinados Sushi"
            
        }

        // props.setShowPromo(false)

        props.addProduct(borbaBurger)
        
    }



    const HandleHours = () =>{
        var date = new Date()

        console.log(date.getDay())

        if(props.shop[0]){
            if(props.shop[0].isOpen){
                switch (date.getDay()) {
                    case 1:
                        return <p style={{color: 'red'}}>Fechado Agora <br></br> <br></br> </p>
                        
                    case 0:                    
                    case 2:
                    case 3:       
                        if(date.getHours() < 12 && date.getHours() > 0 ){
                            return <p style={{color: 'red'}}>Fechado Agora <br></br> <br></br> </p>
                        }            
                        else {
                            
                            return <p></p>
                        }
                    case 4:
                    case 5:
                    case 6:
                    
                        if(date.getHours() < 12 && date.getHours() > 2 ){
                            return <p style={{color: 'red'}}>Fechado Agora <br></br> <br></br> </p>
                        }            
                        else {
                            
                            return <p></p>
                        }

                
                    default:
                        if(date.getHours() < 10 && date.getHours() > 1 ){
                            return <p style={{color: 'red'}}>Fechado Agora <br></br> <br></br> </p>
                        }            
                        else {
                            
                            return <p></p>
                        }
                }


            }    
            else{
                return <p style={{color: 'red'}}>Fechado Agora <br></br></p>
            }           
        }

        else return null;



    }

    const NavBar = () =>{

        const NavIcon = ({sub}) =>{

            switch (sub){
                case 'RapiEsfihas':
                    return <Esfiha className="nav-icon" />
                    break;
                
                case 'Pastel':
                case "Calzone":
                    return <Pastel className='nav-icon' />
                    break;

                case 'Bebidas ':
                    return <Soda className='nav-icon' />
                    break
                
                case 'Sobremesas':
                    return <Sobremesa className='nav-icon' />

                case 'Hot Dog':
                    return <HotDog className='nav-icon' />
                
                case 'Ice':
                    return <Cola className='nav-icon' />

                case 'Corote':
                    return <Corote className='nav-icon' />

                case 'Combos':
                    return <Combo className='nav-icon' />

                case 'Porções  ':
                    return <Porcao className='nav-icon' />

                case 'Rapiburguer':
                case "Kids ":
                case "Xis Tradicional ":
                    return <Burger className='nav-icon' />

                case 'Panquecas':
                    return <Panqueca className='nav-icon' />


                

                default: return <Pizza className='nav-icon' />;
            }
        }

            return <div  className='nav-on-cart'>  
                {subcategories.map(sub=>(
                                        <Link 
                                            onClick={()=>iconMenuClick(sub)} 
                                            className={props.convProps === sub ? "link-nav-on-cart link-nav-cart-active" : "link-nav-on-cart"} 
                                            to="/conveniencia"
                                            >
                                            <NavIcon sub={sub} />
                                            {sub}</Link>
                                    
                ))}
      
            </div>

      
    }

    // console.log(props.shop[0].schedule)




    return (
        <div>
            {/* <Nav /> */}

            <NavBar  />
           
            <div ref={myRef}/>
            {/* <HandleHours /> */}

            {/* <div onClick={addPromo} style={{display: props.showPromo ? 'block' : 'none', width: window.innerWidth > 600 ? '35vw' : 'auto'}}>
                            <h3>Mais Recomendados</h3>
                            <img src={'http://efraimjer.tk/deborba/app/banner%20site.jpg'} style={{width: window.innerWidth > 600 ? '30vw' : '80vw'}} alt="" />
            </div> */}

            {/* <img src={"./banner.jpg"} style={{width: window.innerWidth}} /> */}



            
    </div>
    )
}

const colorStyle = {
    color: '#575151',
    fill: '#575151',
    stroke: '#575151',
}