export const sort = (property) =>{

    const sorted = []
    
    property.map(product=>{
        if(sorted.indexOf(product.group) === -1){
           sorted.push(product.group)
        }
    })

    return sorted;
}

export const sortSub = (property) =>{

    const sorted = []
    
    property.map(product=>{
        if(sorted.indexOf(product.subGroup) === -1){
           sorted.push(product.subGroup)
        }
    })

    return sorted;
}

export const sortAddOns = (addOn, product) =>{

    console.log(addOn.category)

    const sorted = []

    if(product.group === addOn.category){
        return addOn
    }

   
}