import  {React,useEffect, useState} from 'react';
import './components.css';
import './css/checkout.css';
import InputMask from 'react-input-mask';
import axios from 'axios';

import clock from '../assets/Foto-Loading-PNG.png'
import denied from '../assets/denied.png'

// import bairros from './JSON/bairros.json'




import {BiChevronLeftCircle} from 'react-icons/bi'
import {RiMastercardLine} from 'react-icons/ri'
import {FaRegCopy} from 'react-icons/fa'

export default function Payment(props) {

    const[frete, setFrete] = useState(0);
 
    const[savedOrder, setSavedOrder] = useState(JSON.parse(localStorage.getItem('order')) ? JSON.parse(localStorage.getItem('order')) : {})
    

    const[phone, setPhone] = useState('')
    const[adress, setAdress] = useState('')

    const[deliver, setDeliver] = useState(false)

    const[name, setName]= useState('');

    const[newName, setNewName]= useState('')

    const[option, setOption] = useState('')
    const[nb, setNb] = useState('')
    const[city, setCity] = useState('')
    const[houseNumber, setHouseNumber] = useState('')
    const[reference, setReference] = useState('')

    const[loading, setLoading] = useState(false)
    const[paymentProcess, setPaymentProcess] = useState(false)




    const[cep, setCep] = useState(0)



    const[change, setChange] = useState('')

    const date = new Date()

   

    const [payButton, setPayButton] = useState(false)
    const[payButtonText, setPayButtonText] = useState('')


  
    const[tables, setTables] = useState([])
    const[table, setTable] = useState({})
    const[openTable, setOpenTable] = useState(false)


    // const[confirmation, setConfirmation] = useState(false)

    useEffect(() => {

        //TODO response from payment
        axios.get(props.apiUrl + 'tables')
            .then(res=>{
                setTables(res.data)

            })
            .then(err=>console.log(err))

            if(JSON.parse(localStorage.getItem('order'))){
                setNewName(savedOrder.name)
                setPhone(savedOrder.phone)
                setNb(savedOrder.neighborhood)
                setAdress(savedOrder.adress)
            }

            console.log(tables)



    },[table])

  
    const setLocalStorage = (key, value) =>{
        
        localStorage.setItem(key, JSON.stringify(value));

          
    }





    const orderSuccess = (order) =>{

        setLoading(false)
        


        setLocalStorage('order', order)
        props.setCart([])
        props.setExtrasCart([])
        props.setTotal(0)

        props.foo()

        props.handleSuccess()



    }


    const placeOrder = () =>{
        console.log(table[0].cart)
        
        //todo if mesa isTaken place order if !isTaken abrir mesa?

        if(!table.isTaken){
            setOpenTable(true)
            setTable({...table, isTaken: true})
        }

        else{
            if(name.length < 1){
                alert("Você precisa preencher o nome do cliente responsável pela mesa")
            }
            else{
                setLoading(true)
            // setPaymentProcess(true)
            let hereCart = props.cart

            table[0].cart.forEach(cart=>{
                hereCart.push(cart)
            })
            
            var order = {
                'number': table[0].number,
                'name': name,
                'phone': phone,
                'cart': hereCart,
                'total': parseFloat(props.total) + table[0].total,
                'isTaken': true,
                'viewed': false,
                "time": date.toLocaleString('pt-BR'),
                "paid": false
            }   

            console.log(order)

            axios.post(props.apiUrl + 'updateTable/' + table[0]._id, order)
                .then(res=> orderSuccess(table[0]))
                .catch(err=> console.log(err))
            }

        }




    }

    const handleTable = (id) =>{
        // console.log(table._id)
        setTable(tables.filter(table=>{
            if(id === table._id){
                if(!table.isTaken){
                    setOpenTable(true)
                    console.log(table)
                    return table
                }
                else{
                    setOpenTable(false)
                    console.log(table)
                    setName(table.name)
                    setPhone(table.phone)
                    return table
                }
                
            }
        }))
    }








    //TODO quando a mesa não estiver abertar mostrar um modal pedindo nome e contato do cliente
    //todo update table em todo lançamento





    
    return (

        <div className="payment">
            <BiChevronLeftCircle onClick={props.foo}  className="adddecbutton modalclose" style={{color: '#fc4041', position: 'fixed', top: '40px', left: '15px'}}/>
            <div className="flex-column">
                
                <p>{'o total até aqui R$' + props.total.toFixed(2)}</p>

            </div>
            <p>Qual a mesa?</p>
            <select 
                style={{
                    width: '200px'
                }}
                onChange={(e)=>handleTable(e.target.value)}
            >
                <option key={"fake table"} value={""}>Escolha a mesa...</option>
                {tables.map(table=>(
                    <option key={table._id} value={table._id}>{table.number}</option>
                ))}
            </select>

            {openTable && <div>
                <p>abrir mesa?</p>
                <form>
                    <label>nome do cliente</label>
                    <input placeholder='nome do cliente' value={name} onChange={(e)=>setName(e.target.value)} />
                    <label>telefone</label>
                    <input placeholder='telefone do cliente' value={phone} onChange={(e)=>setPhone(e.target.value)} />
                </form>

            </div>}
          

            <span   
                className="payment-button" 
                style={{marginLeft: '0px'}}
                onClick={placeOrder} >
                {!openTable ? "Adicionar à Mesa" : 'Adicionar à Mesa'}
            </span>       


        </div>

        // <div className="payment">
        
        // </div>
    )
}
