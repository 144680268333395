import React from 'react'

export default function FilterRender(props) {
    return (
        <div>
                    {props.menu.map(item =>(
                            <div className="product-box" onClick={()=>{props.foo(item)}} >
                            <div className="img-box">
                                <img src={item.photo} alt=""></img>
                            </div>
                            <div>
                                <h4>{item.name} </h4>
                                <p>{item.short}</p>
                                <p ><b>R$ {item.price.toFixed(2)}</b></p>
            
                            </div>                            
                        </div>
                       
            ))}
            
        </div>
    )
}
