import {React, useState, useEffect} from 'react'

import {BiChevronLeftCircle} from 'react-icons/bi'
import {HiCheck} from 'react-icons/hi'
import {IoIosRemove, IoIosCloseCircle} from 'react-icons/io'
import {GrFormAdd} from 'react-icons/gr'


import useSound from 'use-sound';

import click from '../assets/sound/487452__ranner__click.wav'
import Random from './controllers/Random'
import axios from 'axios'
import { sortAddOns } from '../utils/sort'
import Acompanhamentos from './components.utils/Acompanhamentos'
import Sabores from './components.utils/Sabores'
import FlavourDisplay from './components.utils/FlavourDisplay'
import Bordas from './components.utils/Bordas'
import { bordasF, bordasG, bordasM, bordasP } from './JSON/bordas'

export default function Productmobile(props) { 


    
    const[observation, setObservation] = useState('')
    const[missingPoint , setMissingPoint] = useState(false) 
    const[quantity, setQuantity] = useState(1)
    const[addOns, setAddOns] = useState()
    const[pizzaFlavours, setPizzaFlavours] = useState()
    const[pizzaBorders, setPizzaBorders] = useState()
    
    const [showAddOns, setShowAddOns] = useState(false)
    const [showBorders, setShowBorders] = useState(true)
    const [subSub, setSubSub] = useState(0)

    useEffect(()=>{
        axios.get(props.apiUrl + 'addOns')
        .then(res=> setAddOns(res.data))

        axios.get(props.apiUrl + 'flavours')
        .then(res=> {setPizzaFlavours(res.data)
            
        })

        axios.get(props.apiUrl + 'bordas')
        .then(res=> setPizzaBorders(res.data))
    },[])
    useEffect(()=>{
        // console.log(props.product.group)

        if(props.product.group === "Rapiburguer" || 
        props.product.group === "Porções  " || 
        props.product.group === "Kids " || 
        props.product.group === "Xis Tradicional "){
            console.log('show add ons')
            setShowAddOns(true)
        }

        if(props.product.name === "Pizza P"){
            props.setLimit(1)
            props.setMultiplier(2)
            // console.log("P")
            // handleMultiplier(1, 2)
        }
        else if(props.product.name === "Pizza M"){
            props.setLimit(1)
            props.setMultiplier(2)
            // console.log("M")
            // handleMultiplier(1, 2)
        }
        else if(props.product.name === "Pizza G"){
            props.setLimit(1)
            props.setMultiplier(3)
            console.log("G")
            // handleMultiplier(1, 3)
        }
        else if(props.product.name === "Pizza F"){
            props.setLimit(1)
            props.setMultiplier(4)
            
            // handleMultiplier(1, 4)
        }
        else if(props.product.name === "Pizza Meio Metro"){
            props.setLimit(1)
            props.setMultiplier(5)
            
            // handleMultiplier(1, 4)
        }
        else if(props.product.name === "Pizza Metro"){
            props.setLimit(1)
            props.setMultiplier(8)
            
            // handleMultiplier(1, 4)
        }
        else if(props.product.name === "Calzone G"){
            props.setLimit(1)
            props.setMultiplier(2)
            
            // handleMultiplier(1, 4)
        }
        else if(props.product.name === "Calzone F"){
            props.setLimit(1)
            props.setMultiplier(2)
            
            // handleMultiplier(1, 4)
        }
 

    },[props.product])

    useEffect(()=>{


        if(props.product.name === "Pizza Meio Metro" ||
        props.product.name === "Pizza Metro"
        ){
            console.log('show add ons')
            setShowBorders(false)
        }
        else setShowBorders(true)
    },[props.product])

    const hereAddOns = addOns?.filter(i=>{
            // console.log(props.product)
            // console.log("add on")

            if(props.product.group === 'Bebidas '){
                if(props.product.subGroup === 'Sucos de Polpa' && i.category.indexOf(props.product.group) >= 0 ){
                    return i
                }
            }



            else if(props.product.group === "Porções  "){
                // console.log(props.product)
                    // console.log(i.category[0] === props.product.group)
                    if(i.category[0] === props.product.group){
                        if(props.product.name === "47- Batata Frita " ||
                            props.product.name === "48- Polenta " ||
                            props.product.name === "49- Mandioca "||
                            props.product.name === "50- Batata Smiles"||
                            props.product.name === "51- Batata Rústica"
                        ){
                            if(i.name === "bacon" ||
                                i.name === "calabresa" ||
                                i.name === "cheddar" ||
                                i.name === "muçarela"
                                ){
                                    return i
                                }
                        }
                        else if(props.product.name === "55- Frango à Passarinho " ||
                            props.product.name === "56- Nuggets " ||
                            props.product.name === "57- Frango na Chapa 500g" ||
                            props.product.name === "58- Calabresa na Chapa 400g" ||
                            props.product.name === "59- Tilápia 500g" ||
                            props.product.name === "60- Pernil na Chapa 500g" ||
                            props.product.name === "61- Filé em Tiras 500g" ||
                            props.product.name === "62- Coração na Chapa  500g" ||
                            props.product.name === "63- Camarão 500g" 
                        ){
                            if(i.name === "bacon" ||
                            i.name === "calabresa" ||
                            i.name === "cheddar" ||
                            i.name === "muçarela"
                            ){
                                
                            }
                            else return i
                        }
                        else{


                        }

                    }
                    // console.log(props.product.group)
            }

            else{
                
                if(i.category === props.product.group){
                    // console.log(i)
                    return i
                }
        
                else if(i.category.indexOf(props.product.group) >= 0){
                    return i
                }

            }
        

    })

    // console.log(hereAddOns)

    const hereFlavours = pizzaFlavours?.filter(i=>{

        // console.log(props.product)
        if(props.product.group === 'RapiEsfihas'){

            if(props.product.name === "Esfihas Tradicionais" || props.product.name === "Esfihas Especiais"){
                if(i.subGroup ==="Esfihas Especiais " && props.product.name === "Esfihas Especiais"){
                    return i
                }
                else if (i.subGroup === "Esfiha Salgada" && props.product.name === "Esfihas Tradicionais"){
                    return i
                }
                else if(i.subGroup === "Esfihas Salgadas " && props.product.name === "Esfihas Tradicionais"){
                    return i
                }
            }
            
            else if(i.subGroup === props.product.subGroup){
                
                return i
            }
        }


        // console.log(i.category.indexOf(props.product.group))
       else if(i.category.indexOf(props.product.group) >= 0 ){ 
        
        
        if(props.product.group === "Pastel"){
            if(props.product.name === "102.3- Pastel Doce (sabores de pizza)" || 
            props.product.name === "103.3- Pastel doce com borda (sabores de pizza)"){
                if(i.subGroup === "Doces"){
                    return i
                }
            }

            else if(props.product.name === "102.2 Pastel Especial (sabores de pizza)" || 
            props.product.name === "103.2- Pastel Especial com Borda(sabores de pizza)"){
                if(i.subGroup === "Especiais")
                return i
            }

            
            else if(props.product.name === "102.1- Pastel Tradicional (sabores de pizza)" || 
            props.product.name === "103.1- Pastel Tradicional com borda (sabores de pizza)"){
                if(i.subGroup === "Tradicionais")
                return i
            }

            else {
                if(i.subGroup !== "Doces"){
                    return i
                }
            }
        }

        else if(props.product.group === "Panquecas"){
            if(props.product.name === "104.3- Panqueca Enrolada Doce" || 
            props.product.name === "110- Panqueca Americana Doce"){
                if(i.subGroup === "Doces"){
                    return i
                }
            }

            else if(props.product.name === "104.2- Panqueca Enrolada Especial"){
                if(i.subGroup === "Especiais")
                return i
            }

            
            else if(props.product.name === "104.1- Panqueca enrolada tradicional"){
                if(i.subGroup === "Tradicionais")
                return i
            }

            else {
                if(i.subGroup !== "Doces"){
                    return i
                }
            }

        }

        else return i

                      
            
        }





// if(i.category === props.product.group){
//     return i
// }
})


    const hereBorders = pizzaBorders?.filter(i=>{

        if(i.category === props.product.group){
            return i
        }

    })


    const hereBordersP = bordasP?.filter(i=>{

        if(i.category === props.product.group){
            return i
        }

        else if(props.product.name === "103.1- Pastel Tradicional com borda (sabores de pizza)" || props.product.name === "103.2- Pastel Especial com Borda(sabores de pizza)" || props.product.name === "103.3- Pastel doce com borda (sabores de pizza)" ){
            return i
        }

    })
    const hereBordersM = bordasM?.filter(i=>{

        if(i.category === props.product.group){
            return i
        }

    })

    const hereBordersG = bordasG?.filter(i=>{

        if(i.category === props.product.group){
            return i
        }

    })

    const hereBordersF = bordasF?.filter(i=>{

        if(i.category === props.product.group){
            return i
        }

    })




    const [sushiExtra, setSushiExtra] = useState([
    ])



    const [play] = useSound(click);

    const [extraAdds, setExtraAdds] = useState([
    ])




    const addOne = ()=>{
        setQuantity(current=> current + 1)
    }

    const decreaseOne = ()=>{
        if(quantity > 1){
        setQuantity(current=> current - 1)
        }
        else setQuantity(1)
    }

    function getOccurrence(array, value) {
        var count = 0;
        array.forEach((v) => (v === value && count++));
        return count;
    }

    const addToExtraCart = (extra)=>{

        play()

        props.setExtrasCart(current => [...current, extra])
        setSubSub(current=> current + extra.price * props.multiplier)
       

   
    }

    const addVariation = (variation) =>{
        play()
        if(props.product.variableProduct && props.hereVariations.length > props.product.variationCount - 1){
            alert('Você atingiu o máximo de opções')
        }
        else {
            props.setHereVariations(current=>[...current, variation])
            // console.log(props.hereVariations)
        }

    }

    const decreaseVariation = (variation)=>{
        play()
        props.hereVariations.splice(props.hereVariations.indexOf(variation), 1)

    }



    const decreaseOneExtra = (extra) =>{
        play()
        extra.price = extra.price

        props.extrasCart.splice(props.extrasCart.indexOf(extra), 1)
        setSubSub(current=> current - extra.price * props.multiplier)

        // console.log(props.extrasCart)

    }

    const addBorda = ( borda ) =>{
        play()

        if(borda.onCart){
            borda.onCart = false
            props.bordaCart.splice(props.bordaCart.indexOf(borda), 1)
            setSubSub(current=> current - borda.price)
        }
        else if(props.bordaCart.length === 2){
            alert('Você atingiu o máximo de adicionais')
        }
        else{
            borda.onCart = true;              
            props.setBordaCart(current => [...current, borda])
            setSubSub(current=> current + borda.price)
        }

    }

    const addAdicionais = (addOn, extra) =>{



        if(addOn.onCart){

            addOn.onCart = false
            props.extrasCart[props.extrasCart.findIndex(el=>{
                return el.code === extra.code
            })]
                .adicionais.splice(props.extrasCart[props.extrasCart.findIndex(el=>{
                    return el.code === extra.code
                })]
                .adicionais.indexOf(addOn), 1)
            // props.addOnCart.splice(props.addOnCart.indexOf(extra), 1)
            setSubSub(current=> current - addOn.price * props.multiplier)
            

        }
        else if(props.extrasCart[props.extrasCart.length - 1].adicionais?.length === 3){
            alert('Você atingiu o máximo de adicionais')
        }
        else {

            if(props.extrasCart[props.extrasCart.findIndex(el=>{
                return el.code === extra.code
            })].adicionais){
                // console.log(props.extrasCart[props.extrasCart.length - 1])
                addOn.onCart = true;
                let addhere = props.extrasCart[props.extrasCart.findIndex(el=>{
                    return el.code === extra.code
                })].adicionais
                addhere.push(addOn)
                props.extrasCart[props.extrasCart.findIndex(el=>{
                    return el.code === extra.code
                })].adicionais = addhere

                setSubSub(current=> current + addOn.price * props.multiplier)
            }
            else{          
                addOn.onCart = true;
                props.extrasCart[props.extrasCart.findIndex(el=>{
                    return el.code === extra.code})].adicionais = [addOn]

                
                    setSubSub(current=> current + addOn.price * props.multiplier)
                // props.setAddOnCart(current => [...current, extra])
                
            }
        }

    }

    const addAddOn = (extra, parent) =>{

        

        play()
        // console.log(props.product)
        if(props.product.group === "Rapiburguer" || props.product.group === "Xis Tradicional " || props.product.group === "Kids "|| props.product.group === "Porções  " || extra.name.toUpperCase().includes("BORDA")){
            // console.log(extra)
            if(extra.onCart){
                extra.onCart = false
                props.addOnCart.splice(props.addOnCart.indexOf(extra), 1)
                setSubSub(current=> current - extra.price * props.multiplier)
            }
            else if(props.extrasCart.length === 1){
                alert('Você atingiu o máximo de adicionais')
            }
            else{
                extra.onCart = true;              
                props.setAddOnCart(current => [...current, extra])
                setSubSub(current=> current + extra.price * props.multiplier)
            }

        }
        else{
            // console.log('errou')
            if(extra.onCart){

                extra.onCart = false
                props.extrasCart[props.extrasCart.length - 1]
                    .adicionais.splice(props.extrasCart[props.extrasCart.length - 1]
                    .adicionais.indexOf(extra), 1)
                    setSubSub(current=> current - extra.price * props.multiplier)
                // props.addOnCart.splice(props.addOnCart.indexOf(extra), 1)
                
    
            }
            else if(props.extrasCart[props.extrasCart.length - 1].adicionais?.length === 3){
                alert('Você atingiu o máximo de adicionais')
            }
            else {
                if(props.extrasCart[props.extrasCart.length - 1].adicionais){
                    console.log(props.extrasCart[props.extrasCart.length - 1])
                    extra.onCart = true;
                    let addhere = props.extrasCart[props.extrasCart.length - 1].adicionais
                    addhere.push(extra)
                    props.extrasCart[props.extrasCart.length - 1].adicionais = addhere
                    setSubSub(current=> current + extra.price * props.multiplier)
                }
                else{  
                    console.log("parent", props.extrasCart[props.extrasCart.indexOf(parent)])          
                    extra.onCart = true;
                    props.extrasCart[props.extrasCart.length - 1].adicionais = [extra]
                    setSubSub(current=> current + extra.price * props.multiplier)
                    // props.setAddOnCart(current => [...current, extra])
                    
                }
            }
        }


    }

    const addOneExtra  = (extra) =>{
        play()

        console.log(extra)

        extra.addOns.forEach(addon =>{
            addon.onCart = false
        })

        
        


        // if(extra.onCart){
        //     extra.onCart = false
        //     // props.extrasCart.indexOf(extra).addOns.forEach(add =>{
        //     //     add.onCart = false
        //     // })
        //     props.extrasCart.splice(props.extrasCart.indexOf(extra), 1)

        // }
         if(props.product.group === 'Pastel'&& props.extrasCart.length === 1){
            alert('Você atingiu o máximo de opções')
        }
        else if(props.extrasCart.length === props.limit && props.product.group !== "RapiEsfihas"){
            alert('Você atingiu o máximo de opções')
        }

        else if(props.product.group === "RapiEsfihas"){
            extra.onCart = true

            props.extrasCart.length > 0 && setQuantity(quantity + 1)

            let localExtra = {
                code: extra.code,
                name: extra.name,
                price: extra.price,
                available: extra.available,
                category: extra.category,
                onCart: extra.onCart,
                addOns: extra.addOns,
                adicionais: extra.adicionais,
                subGroup: extra.subGroup,
                short: extra.short
            }
            localExtra.price = extra.price * props.multiplier
            localExtra.onCart = true
            props.setExtrasCart(current => [...current, localExtra])
            setSubSub(current=> current + extra.price * props.multiplier)

        }
        else {
            console.log(extra)
            extra.onCart = true
            let localExtra = {
                code: extra.code,
                name: extra.name,
                price: extra.price,
                available: extra.available,
                category: extra.category,
                onCart: extra.onCart,
                addOns: extra.addOns,
                adicionais: extra.adicionais,
                subGroup: extra.subGroup,
                short: extra.short
            }
            localExtra.price = extra.price * props.multiplier
            localExtra.onCart = true
            props.setExtrasCart(current => [...current, localExtra])
            // extra.price = extra.price
            // console.log(extra)
            setSubSub(current=> current + extra.price * props.multiplier )
        }
    }

    const addToCart = (product) =>{
  
        if(props.plusBurger && props.p === ""){
            setMissingPoint(true)
        }

        else if(props.product.flavours.length > 0 && props.p === "" && props.product.flavours[0] !== ""){
            setMissingPoint(true)
        }

        else if(props.product.group === "Pizzas" && props.extrasCart.length <= 0){
            console.log(props.addOnCart)
            alert("Você precisa adicionar ao menos um sabor")
        }

        else if(props.product.group === "Pizzas" && props.extrasCart.length !== props.limit){
            console.log(props.addOnCart)
            alert("Você precisa escolher mais " + (props.limit - (props.extrasCart.length) )+ " sabores")
        }   


        
        else{        

       
        
        props.product.quantity = quantity;

        props.product.subTotal = quantity * props.product.price;

        props.addToCart(product)

        setQuantity(1)


        addOns.forEach(element=>{
            element.onCart = false
        })

        pizzaFlavours.forEach(element=>{
            element.onCart = false
        })

        pizzaBorders.forEach(element=>{
            element.onCart = false
        })

        hereBordersP.forEach(element=>{
            element.onCart = false
        })

        hereBordersM.forEach(element=>{
            element.onCart = false
        })
        hereBordersG.forEach(element=>{
            element.onCart = false
        })

        hereBordersF.forEach(element=>{
            element.onCart = false
        })

        props.setHereVariations([])
        
        setObservation('')}
        }
        

    const handleObs = (value) =>{    
        props.setObservation(value)
    }

    const HandleBorders = () =>{
        
        if(props.product.name === "Pizza P"){
            return <Bordas
                data={hereBordersP}
                getOccurrence={getOccurrence}
                addToExtraCart={addOneExtra}
                decreaseOneExtra={decreaseOneExtra}
                addAddOn={addAddOn}
                addBorda={addBorda}
                extrasCart={props.extrasCart}
                title={'Bordas'} />
        }
        else if(props.product.name === "Pizza M"){
            return <Bordas
            data={hereBordersM}
            getOccurrence={getOccurrence}
            addToExtraCart={addOneExtra}
            decreaseOneExtra={decreaseOneExtra}
            addAddOn={addAddOn}
            addBorda={addBorda}
            extrasCart={props.extrasCart}
            title={'Bordas'} />
        }
        else if(props.product.name === "Pizza G"){
            return <Bordas
            data={hereBordersG}
            getOccurrence={getOccurrence}
            addToExtraCart={addOneExtra}
            decreaseOneExtra={decreaseOneExtra}
            addAddOn={addAddOn}
            addBorda={addBorda}
            extrasCart={props.extrasCart}
            title={'Bordas'} />
        }

        else if(props.product.name === "Pizza F"){
            return <Bordas
            data={hereBordersF}
            getOccurrence={getOccurrence}
            addToExtraCart={addOneExtra}
            decreaseOneExtra={decreaseOneExtra}
            addAddOn={addAddOn}
            addBorda={addBorda}
            extrasCart={props.extrasCart}
            title={'Bordas'} />
        }
        else if(props.product.name === "103.1- Pastel Tradicional com borda (sabores de pizza)" 
            || props.product.name === "103.2- Pastel Especial com Borda(sabores de pizza)" 
            || props.product.name === "103.3- Pastel doce com borda (sabores de pizza)"
        ){
            return <Bordas
            data={hereBordersP}
            getOccurrence={getOccurrence}
            addToExtraCart={addOneExtra}
            decreaseOneExtra={decreaseOneExtra}
            addAddOn={addAddOn}
            addBorda={addBorda}
            extrasCart={props.extrasCart}
            title={'Bordas'} />
        }
        

        else return null
    }

    const handleBackBtn = () =>{
        hereFlavours.forEach(item =>{
            if(item.addOns){
                item.addOns.forEach(add=>{
                    add.onCart = false
                })
            }
        })

        hereFlavours.forEach(item =>{
            item.onCart = false
        })
        
        props.showPmobile()
        props.setLimit(1)
        props.setMultiplier(1)
    }


    return (
        <div className="product-mobile" 
        style={{display: props.pMobile ? 'flex' 
        : 'none', 
        paddingTop: props.product.photo?.length > 0 ? '0' 
        : '50px', paddingBottom: '150px', }}>

                <div className="close-mobile">
                    <BiChevronLeftCircle onClick={handleBackBtn}  />
                    <p>{props.product.name}</p>
                </div>

                {props.product.photo?.length > 0         &&        <div className='img-wrapper-pmobile'>
                    <img alt="" src={props.product.photo} />
                </div> }

                {props.product.short?.length > 0 && <p >{props.product.short}</p>}
                {props.product.long?.length > 0 && <p>{props.product.long}</p>}

                <div className="price-tag-mobile">
                    <p>Valor Unitário R$ {props.product.price ? props.product.promo ? props.product.promoPrice.toFixed(2) : props.product.price.toFixed(2) : ''}</p>                  
                </div>

                <FlavourDisplay 
                    productName={props.product.name} 
                    setLimit={props.setLimit} 
                    limit={props.limit} 
                    multiplier={props.multiplier}
                    setMultiplier={props.setMultiplier}
                    setExtrasCart={props.setExtrasCart}
                    pizzaFlavours={pizzaFlavours}
                />
                
                <form style={{display: props.product.flavours? props.product.flavours.length > 0  && props.product.flavours[0] !== "" ? 'flex' : 'none' : 'none', justifyContent: 'center', alignItems: 'center',flexDirection: 'column', marginBottom: "10px"}}>
                        <label>{props.product.group === "Rapiburguer" ? 'Escolha o Pão:' : 'Escolha o Sabor:'} </label>

                        <select style={{width: '200px'}} value={props.p} onFocus={()=>{setMissingPoint(false)}}onChange={(e)=>props.handleMeatPoint(e.target.value)}>
                        <option value="">-</option>
                        {props.product.flavours ? props.product.flavours.map(flavour=>(
                            <option value={flavour}>{flavour}</option>
                        )) : null}
                        </select>
                        <p style={{display: missingPoint ? 'block' : 'none', color: 'red'}}>escolha um sabor</p>
                </form>

                <p></p>

                    <form style={{display: props.product.variations ? 'flex' : 'none', flexDirection: "column", alignItems: 'center'}}>
                    <label>{props.product.variations?.length > 0 && 'Escolha o Molho:'} </label>
                    {props.product.variations  && <select style={{width: '200px'}} value={props.hereVariations[0]} onFocus={()=>{setMissingPoint(false)}} onChange={(e)=>addVariation(e.target.value)}>
                        <option value="">-</option>
                        {props.product.variations ? props.product.variations.map(flavour=>(
                            <option value={flavour}>{flavour}</option>
                        )) : null}
                        </select>} 
              

            </form>

            {showBorders && hereBorders?.length >= 1 && <HandleBorders/>

            }
            {showBorders && props.product.group === "Pastel" && <HandleBorders/>}

            {hereFlavours?.length >= 1 && <Sabores 
            data={hereFlavours}
            getOccurrence={getOccurrence}
            addToExtraCart={addOneExtra}
            decreaseOneExtra={decreaseOneExtra}
            addOneExtra={addOneExtra}
            extrasCart={props.extrasCart}
            addAddOn={addAddOn}
            addAdicionais={addAdicionais}
            title={'Sabores'}
            product={props.product}
            multiplier={props.multiplier}
            
            />}

            {showAddOns  && hereAddOns?.length >= 1 && <Acompanhamentos 
            data={hereAddOns}
            getOccurrence={getOccurrence}
            addToExtraCart={addOneExtra}
            decreaseOneExtra={decreaseOneExtra}
            addAddOn={addAddOn}
            extrasCart={props.extrasCart}
            title={'Adicionais'}
            />} 



            <form style={{display: props.plusBurger ? 'block' : 'none'}}>
            <p>Adicionais</p>
            {/* {burgerExtra.map(extra =>(
                    <div>
                        
                        <div className="extra-span" onClick={()=>{addOneExtra(extra)}}>
                        <div><HiCheck style={{color: 'green', fontSize: '1.5em', display: extra.onCart ? 'block' : 'none'}} /></div>
                                {extra.name} <b>R$ {extra.price.toFixed(2)}</b>

                            </div>
                    </div>
                ))}  */}

            </form>

            <form style={{display: props.sushiExtra ? 'block' : 'none'}}>
            <p>Adicionais</p>
            {sushiExtra.map(extra =>(
                    <div>
                        
                        <div className="extra-span" >
                                    <p style={{marginLeft: '0px', marginTop: '0px'}} >
                                    <IoIosRemove onClick={()=>{decreaseOneExtra(extra)}} style={{ fontSize: '1.5em', marginTop: '0px', marginRight: '5px'}} />
                                    {getOccurrence(props.extrasCart, extra)}
                                    <GrFormAdd style={{fontSize: '1.5em', marginLeft: '5px'}} onClick={()=>{addToExtraCart(extra)}}  />
                                    
                                </p>
                                {extra.name } <b>R$ {extra.price.toFixed(2)}</b>
                            </div>
                    </div>
                ))} 

            </form>

            <form style={{display: props.varProduct ? 'block' : 'none'}}>
            <p>Adicionais</p>
            {extraAdds.map(extra =>(
                    <div>
                        
                        <div className="extra-span"  >
                        {/* <div><HiCheck style={{color: 'green', fontSize: '1.5em', display: extra.onCart ? 'block' : 'none'}} /></div> */}
                                <p style={{marginLeft: '0px', marginTop: '0px'}}>
                                    <IoIosRemove onClick={()=>{decreaseOneExtra(extra)}} style={{ fontSize: '1.5em', marginTop: '0px', marginRight: '5px'}} />
                                    {/* {getOccurrence(props.extrasCart, extra)} */}
                                    {getOccurrence(props.extrasCart, extra)}
                                    <GrFormAdd style={{fontSize: '1.5em', marginLeft: '5px'}} onClick={()=>{addToExtraCart(extra)}}  />
                                    
                                </p>
                                <div >{extra.name}</div> <b>R$ {extra.price.toFixed(2)}</b>
                                
                            </div>
                    </div>
                ))} 

            </form>
            <form className="obs-product">
                
                <label style={{fontSize: '1.2em'}}>Observação</label>
                <input style={{width: "80vw", height: '40px', borderRadius: '10px' }} type="text" value={props.observation} placeholder="escreva aqui sua observação?" onChange={(e)=>{handleObs(e.target.value)}}></input>
            </form>

            
            {/* <p style={{WebkitTextDecorationLine: 'line-through', textDecoration:  'line-through #6b6e7e', fontSize: '0.8em'}}>{props.product.promo ? 'R$ ' + props.promoPrice : ''}</p> */}

                    <div className="flex-row" style={{
                        position: 'fixed',
                        bottom: '50px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '80%',
                        backgroundColor: 'white'
                    }}>
                        <div className="product-mobile-quantity">                            
                            <IoIosRemove style={{fontSize: '1.5em', marginRight: '5px'}} onClick={decreaseOne}   />
                            <input type="number" style={{height: '20px'}} value={quantity} />
                            <GrFormAdd style={{fontSize: '1.5em', marginLeft: '5px'}} onClick={addOne}  />

                        </div>
                    
                        <h4 style={{marginTop: "30px", textAlign: 'right', marginRight: "20px"}}> {'subtotal R$ ' + ((props.subtotal * quantity) + parseFloat(subSub)).toFixed(2)}</h4>
                    </div>

                    {/* <p style={{
                        marginBottom: '-50px',
                        width: '80vw',
                        color: '#CA3B00'
                    }}>compre também</p>
                    <Random productsDB={props.productsDB} foo={props.addToCart} /> */}

                    <span style={buttonStyle} onClick={()=>{addToCart(props.product)}}
                   >Adicionar ao pedido</span>
                                               
            
        </div>
    )


}

const buttonStyle = {
    alignSelf: 'center', 
    justifySelf: 'flex-end',
    position: 'fixed',
    bottom: '5px'
}
